<div class="container">
    <form>
        <div class="row row-cols-lg-2 row-cols-1">
            <div class="col">
                <div class="input-group mb-3 position-relative">
                    <label class="form-label fs-sm fw-semibold w-100" for="name">إسم مقدم الطلب</label>
                    <div class="position-relative w-100">
                        <input class="form-control ps-5" id="name" type="text" placeholder="إسم مقدم الطلب" name="name">
                        <i class="bx bx-user fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 opacity-50"></i>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="input-group mb-3 position-relative">
                    <label class="form-label fs-sm fw-semibold w-100" for="email">البريد الإلكتروني</label>
                    <div class="position-relative w-100">
                        <input class="form-control ps-5" id="email" type="email" placeholder="البريد الإلكتروني" style="direction: rtl;">
                        <i class="bx bx-envelope fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 opacity-50"></i>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="input-group mb-3 position-relative">
                    <label class="form-label fs-sm fw-semibold w-100" for="mobile">رقم الجوال</label>
                    <div class="position-relative w-100">
                        <input class="form-control ps-5" id="mobile" type="number" placeholder="رقم الجوال" style="direction: rtl;" max="10">
                        <i class="bx bx-mobile-alt fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 opacity-50"></i>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="input-group mb-3 position-relative">
                    <label class="form-label fs-sm fw-semibold w-100" for="institute">إسم الجهة التابع لها</label>
                    <div class="position-relative w-100">
                        <input class="form-control ps-5" id="institute" type="text" placeholder="إسم الجهة التابع لها">
                        <i class="bx bx-building fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 opacity-50"></i>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="input-group mb-3 position-relative">
                    <label class="form-label fs-sm fw-semibold w-100" for="data-type">نوع البيانات المطلوبة</label>
                    <div class="position-relative w-100">
                        <input class="form-control ps-5" id="data-type" type="text" placeholder="نوع البيانات المطلوبة">
                        <i class="bx bx-data fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 opacity-50"></i>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="input-group mb-3 position-relative">
                    <label class="form-label fs-sm fw-semibold w-100" for="data-time">الإطار الزمني للبيانات</label>
                    <div class="position-relative w-100">
                        <input class="form-control ps-5" id="data-time" type="text" placeholder="الإطار الزمني للبيانات">
                        <i class="bx bxs-timer fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 opacity-50"></i>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="input-group mb-3 position-relative">
                    <label class="form-label fs-sm fw-semibold w-100" for="request-just">مبررات الطلب</label>
                    <div class="position-relative w-100">
                        <input class="form-control ps-5" id="request-just" type="text" placeholder="مبررات الطلب">
                        <i class="bx bxs-file fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 opacity-50"></i>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="input-group mb-3 position-relative">
                    <label class="form-label fs-sm fw-semibold w-100" for="textarea-input">وصف الطلب</label>
                    <textarea class="form-control fs-md" id="textarea-input" rows="4"></textarea>
                </div>
            </div>
        </div>
        <div class="row row-cols-1 mt-4">
            <div class="d-flex justify-content-center">
                <button class="btn btn-primary fs-sm border-2 w-lg-25 w-sm-50 w-100 ms-2">إرسال الطلب</button>

            </div>
        </div>
    </form>
</div>