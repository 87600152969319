import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-sef',
  templateUrl: './sef.component.html',
  styleUrls: ['./sef.component.css']
})
export class SEFComponent implements OnInit {
  content ='';
  _lang = '';


  constructor(private apiServices : ApiService, private actRoute: ActivatedRoute,  private apiResources: ResourcesService, private loading: LoadingService) {
    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }
  }

  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiResources.clearResource();
  }

  ngOnInit(): void {
  }

}
